import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <p>
          This domain{' '}
          <a className='App-link' href='https://techowl.com'>
            <code>techowl.com</code>
          </a>{' '}
          is available for sale or rental.
          <br />
          <br />
          Please inquire at{' '}
          <a
            className='App-link'
            href='mailto:hello@techowl.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            <code>hello@techowl.com</code>
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
